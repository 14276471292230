import _, { forIn } from 'lodash'
import { defineStore } from 'pinia'
import { i18n } from '@/main.js'
import { api } from '@/services/api'
import { timeFunctions } from '@/services/time'
import { errorFunctions } from '@/services/errors'
import { router } from '@/router/router'

let previousFullData = null

export const useGameStore = defineStore({
  id: 'game',
  state: () => ({
    teamCacheId: localStorage.teamCacheId ?? null,
    teamToken: localStorage.teamToken ?? null,
    appVer: 'v2.124',

    routePath: 'stage',
    myUTCOffset: null,
    firstFetchCompleted: 0, // Первый fetch отправлен и получен
    showFetchError: 0,
    progressStatus: '',
    gameErrorMsg: '',
    lastAnswerResult: {
      answer: null,
      stageId: null,
      invisible: 0,
      result: null,
      timeoutId: null,
      showDT: null // дата-время последнего показа результата кода
    },

    answerInput: '', // текущий ответ пользователя в поле ввода
    progressAP: 0, // Процент прошедшего времени до АП
    secondsUntilAP: 0, // секунд осталось до АП
    prevTipAngle: 0, // Прошлое значение угла кругового таймера до подсказки
    stageTime: '',
    stageTimeAP: '',
    allowChooseStage: 0,
    allowInterruptStage: 0,
    allowReturnToPreviousStage: 0,
    notify: {
      tabTips: 0
    },
    dialogs: {
      sureChooseStage: { open: 0, toStageId: null },
      sureNextStage: { open: 0, fromStageId: null }
    },

    team: {},
    game: {},
    stage: {},
    stageTelemetry: { stopwatch: 0, stageTimeAP: 0 },
    stages: [],
    stagesForChoose: [],
    teamAnswersList: []
  }),

  actions: {
    async GetState(shouldRepeat) {
      if (!localStorage.teamCacheId || !localStorage.teamToken) {
        this.progressStatus = i18n.global.t('error')
        this.gameErrorMsg = i18n.global.t('errors.noAuth')
        return 0
      }

      await api.AddToQueue(
        import.meta.env.VITE_API_PATH + 'get-state.php',
        {
          teamCacheId: localStorage.teamCacheId,
          token: localStorage.teamToken
        },
        this.PrepareGetState,
        this.PrepareAjaxError,
        async () => {
          if (shouldRepeat) {
            setTimeout(async () => {
              await this.GetState(true)
            }, 10000)
          }
        }
      )
    },
    async SendAnswer() {
      await api.AddToQueue(
        import.meta.env.VITE_API_PATH + 'get-state.php',
        {
          teamCacheId: localStorage.teamCacheId,
          token: localStorage.teamToken,
          teamAnswer: this.answerInput,
          answerStageId: this.stage?.id,
          clientUid: localStorage['clientUid'] // ???
        },
        this.PrepareGetState,
        this.PrepareAjaxError,
        async () => {}
      )
    },
    async ResetProgress() {
      await api.AddToQueue(
        import.meta.env.VITE_API_PATH + 'reset-team-progress.php',
        {
          teamCacheId: localStorage.teamCacheId,
          token: localStorage.teamToken
        },
        async () => {
          this.ResetLocalStorage()
          router.push('/').then(() => {
            window.location.reload()
          })
        },
        this.PrepareAjaxError,
        async () => {}
      )
    },
    async IgnoreStartTimer() {
      await api.AddToQueue(
        import.meta.env.VITE_API_PATH + 'ignore-start-timer.php',
        {
          teamCacheId: localStorage.teamCacheId,
          token: localStorage.teamToken
        },
        async () => {
          location.reload()
        },
        this.PrepareAjaxError,
        async () => {}
      )
    },
    async ChooseStage(stageId) {
      await api.AddToQueue(
        import.meta.env.VITE_API_PATH + 'choose-stage.php',
        {
          teamCacheId: localStorage.teamCacheId,
          token: localStorage.teamToken,
          stageId
        },
        async () => {
          router.push('/')
          this.GetState(false)
        },
        this.PrepareAjaxError,
        () => {
          this.dialogs.sureChooseStage.open = 0
        }
      )
    },
    async GoToNextStage(fromStageId) {
      await api.AddToQueue(
        import.meta.env.VITE_API_PATH + 'go-to-next-stage.php',
        {
          teamCacheId: localStorage.teamCacheId,
          token: localStorage.teamToken,
          currentStageId: fromStageId
        },
        async () => {
          // router.push('/')
          this.GetState(false)
        },
        this.PrepareAjaxError,
        () => {
          this.dialogs.sureNextStage.open = 0
        }
      )
    },
    async MarkFirstFetchCompleted() {
      if (!this.firstFetchCompleted) await timeFunctions.Delay(1000)
      this.firstFetchCompleted = 1
    },
    async PrepareAjaxError(error) {
      switch (error) {
        case 'no_auth':
          this.progressStatus = 'error'
          this.gameErrorMsg = i18n.global.t('errors.noAuth')
          break

        case 'no_route':
          this.progressStatus = 'error'
          this.gameErrorMsg = i18n.global.t('errors.noRoute')
          break

        default:
          errorFunctions.HandlerAjaxError(error)
          break
      }
    },

    // меняем стили на странице
    InjectEmbeddedStyles(cssString, cssLevel) {
      let styleElement = null
      let styleElementId = 'css-' + cssLevel
      let styleElementExist = Boolean(document.querySelector('#' + styleElementId))
      if (styleElementExist) {
        styleElement = document.querySelector('#' + styleElementId)
        styleElement.textContent = cssString ?? ''
      } else {
        styleElement = document.createElement('style')
        styleElement.textContent = cssString ?? ''
        styleElement.id = styleElementId
        document.head.appendChild(styleElement)
      }
    },
    // меняем JS-код
    InjectEmbeddedJS(jsString, jsLevel) {
      let jsElement = null
      let jsElementId = 'js-' + jsLevel
      let jsElementExist = Boolean(document.querySelector('#' + jsElementId))
      if (jsElementExist) {
        jsElement = document.querySelector('#' + jsElementId)
        jsElement.textContent = jsString ?? ''
      } else {
        jsElement = document.createElement('script')
        jsElement.textContent = jsString ?? ''
        jsElement.id = jsElementId
        document.body.appendChild(jsElement)
      }
    },

    // Помечаем просмотренные подсказки
    MarkViewedTips() {
      if (localStorage.getItem('viewedTips') === null)
        localStorage['viewedTips'] = JSON.stringify([])

      if (!this.stage) return false

      let tips = this.stage?.tips?.shown ?? []
      let viewedTips = JSON.parse(localStorage['viewedTips']) ?? []
      for (let i = 0; i < tips.length; i++) {
        let tip = tips[i]
        let tipId = tip['id']
        if (!viewedTips.includes(tipId)) {
          viewedTips.push(tipId)
        }
      }

      localStorage['viewedTips'] = JSON.stringify(viewedTips)
      this.notify.tabTips = 0
    },

    // Берем список подсказок от сервера и смотрим, есть ли новые.
    // Если есть - уведомляем игрока
    NewTipsNotify(tips) {
      if (localStorage.getItem('viewedTips') === null)
        localStorage['viewedTips'] = JSON.stringify([])

      if (!tips || !tips.length) {
        this.notify.tabTips = 0
        return false
      }

      let viewedTips = JSON.parse(localStorage['viewedTips']) ?? []
      let newTipsCount = 0
      for (let i = 0; i < tips.length; i++) {
        let tip = tips[i]
        let tipId = tip['id']
        if (!viewedTips.includes(tipId)) {
          newTipsCount++
        }
      }

      if (newTipsCount) {
        if (this.routePath == 'tips') this.notify.tabTips = 0
        else this.notify.tabTips = newTipsCount
      }
    },
    // Если есть выданные по времени подсказки, то возвращаем время от начала этапа до последней, иначе возвращаем 0
    CalcLastTipTime() {
      if (!this.stage?.tips?.shown) return 0

      let lastTipSec = 0
      for (let i = 0; i < this.stage.tips.shown.length; i++) {
        const shownTip = this.stage.tips.shown[i]
        if (shownTip?.triggerInt > lastTipSec) lastTipSec = shownTip.triggerInt
      }
      return lastTipSec
    },
    // Высчитываем стиль для кругового таймера до подсказки
    CalcTipTimerAngle() {
      let elapsedSeconds = this.stage?.msOnStage / 1000 ?? 0
      if (Number.isNaN(elapsedSeconds)) return this.prevTipAngle

      let lastTipTime = this.CalcLastTipTime()
      elapsedSeconds -= lastTipTime

      let totalSeconds = this.stage?.toNextTip ? this.stage.toNextTip.sec : 0
      totalSeconds -= lastTipTime

      if (!elapsedSeconds || !totalSeconds) {
        this.prevTipAngle = 0
        return this.prevTipAngle
      }

      this.prevTipAngle = timeFunctions.ElapsedSecondsToAngle(elapsedSeconds, totalSeconds)
      return this.prevTipAngle
    },
    ToAnswersLog() {},
    ResetLocalStorage() {
      localStorage['viewedTips'] = JSON.stringify([])
    },
    GetAnswerResultDesc(result) {
      if (result == 'correct') return `[${i18n.global.t('answersLogs.correct')}]`
      if (result == 'wrong') return `[${i18n.global.t('answersLogs.wrong')}]`
      if (result == 'again') return `[${i18n.global.t('answersLogs.again')}]`
      return null
    },
    PrepareTeamAnswersList() {
      if (!this.teamAnswersList?.length) return ''

      let html = ''
      for (let i = 0; i < this.teamAnswersList.length; i++) {
        const answer = this.teamAnswersList[i]
        let answerResultClass = 'text-success'
        if (answer.result == 'wrong') answerResultClass = 'text-danger'
        if (answer.result == 'again') answerResultClass = 'text-warning'
        html += `<div class="teamAnswersItemDT ${answerResultClass}">
              ${timeFunctions.UTCToLocal(answer.dtSend, {
                short: true
              })}
            </div>`
        html += `<div class="teamAnswersItemresult ${answerResultClass}">
              ${this.GetAnswerResultDesc(answer.result)}
            </div>`
        html += `<div class="teamAnswersItemAnswer ${answerResultClass}">
              ${answer.answer}
            </div>`
      }
      return html
    },
    CountAllOccurrences(array) {
      const counts = {}
      for (const item of array) {
        counts[item] = (counts[item] || 0) + 1
      }
      return counts
    },
    PrepareRemainingAnswersList(wrapElements) {
      if (!this?.stage?.difficulties?.length) return ''

      let difficulties = this?.stage?.difficulties
      let resObj = this.CountAllOccurrences(difficulties)
      let html = ''

      // Оборачиваем результат в div?
      if (wrapElements)
        for (let diff in resObj) {
          html += `<div>
                    ${diff} ${resObj[diff] > 1 ? `(${resObj[diff]})` : ''}
                  </div>`
        }
      else {
        let resArr = []
        for (let diff in resObj) {
          resArr.push(resObj[diff] > 1 ? `${diff} (${resObj[diff]})` : diff)
        }
        html = resArr.join(', ')
        // console.log(resArr)
      }
      return html
    },
    ShowAnswerResult(answerResult) {
      this.lastAnswerResult.answer = answerResult.answer
      this.lastAnswerResult.stageId = answerResult.stageId
      this.lastAnswerResult.result = answerResult.result
      this.lastAnswerResult.invisible = Number(answerResult.invisible)

      // Очистка предыдущего таймера, если он есть
      if (this.lastAnswerResult.timeoutId) clearTimeout(this.lastAnswerResult.timeoutId)

      this.lastAnswerResult.timeoutId = setTimeout(() => {
        if (this.lastAnswerResult.answer == answerResult.answer) {
          this.lastAnswerResult.answer = null
          this.lastAnswerResult.stageId = null
          this.lastAnswerResult.result = null
          this.lastAnswerResult.invisible = 0
        }
      }, 2500)
    },
    SetLocale(lng) {
      document.documentElement.lang = lng
      i18n.global.locale = lng
    },
    async PrepareGetState(fetchResult, sendDT) {
      if (!fetchResult) return false

      let fullData = api.PrepareContent(fetchResult.data)
      // Сравниваем с прошлым fullData
      if (_.isEqual(fullData, previousFullData)) return false // Если данные одинаковые, ничего не делаем
      previousFullData = fullData

      await this.MarkFirstFetchCompleted()

      // Если есть результат оценки ответа команды
      if (fullData.answerResult) {
        this.ShowAnswerResult(fullData.answerResult)
        this.answerInput = ''
      }
      this.myUTCOffset = this.myUTCOffset ?? timeFunctions.CalcUTCOffset(sendDT)

      // Навигация в зависимости от fullData.status
      if (fullData.status == 'choose-stage') router.push('/chooseStage')
      this.progressStatus = fullData.status

      this.game = fullData.game ? fullData.game : null
      localStorage.gameId = fullData?.game?.id
      this.InjectEmbeddedStyles(this.game.css, 'game')
      if (fullData?.currentStage) {
        this.stage = fullData.currentStage
        this.stagesForChoose = fullData.stagesForChoose
        this.InjectEmbeddedStyles(this.stage.css, 'stage')
        this.InjectEmbeddedJS(this.stage.js, 'stage')
      } else {
        this.stage = null
        this.stagesForChoose = []
        this.InjectEmbeddedStyles('', 'stage')
        this.InjectEmbeddedJS('', 'stage')
      }
      this.stages = fullData.stages
      this.allowChooseStage = fullData.allowChooseStage
      this.allowInterruptStage = fullData.allowInterruptStage
      this.allowReturnToPreviousStage = fullData.allowReturnToPreviousStage

      this.team = fullData?.team ?? {}
      this.teamAnswersList = fullData?.currentStage?.teamAnswersList ?? []
      this.NewTipsNotify(fullData.currentStage.tips.shown)

      // head страницы + локаль
      document.title = this.game?.name
      let gameLng = 'en'
      if (this.game?.lng == 'ru') gameLng = 'ru'
      this.SetLocale(gameLng)
    }
  },

  getters: {
    weAreInGame: (state) => state.firstFetchCompleted,
    showOnStageData: (state) => {
      return state.stage.hideInterface == 0 && state.currentTab != 'answersLog'
    }
  }
})
